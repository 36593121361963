"use client";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";
import Image from "next/image";
import { useRouter } from "next/navigation";
import Footer from "./(main)/_components/footer";
import Header from "./(main)/_components/header";

export default function NotFound() {
	const route = useRouter();
	return (
		<>
			<Header />
			<main className="container flex items-center max-md:flex-col-reverse">
				<div className="max-md:text-center">
					<h1 className="text-[60px] font-bold">Página não encontrada</h1>
					<p className="text-[#475467]/80">
						Desculpe, a página que você procura não existe. Aqui estão alguns
						links úteis:
					</p>
					<div className="mt-4 flex items-center justify-start gap-4 max-md:justify-center">
						<Button variant={"outline"} onClick={() => route.back()}>
							<ArrowLeft />
							Voltar
						</Button>
						<Button onClick={() => route.push("/")}>Inicio</Button>
					</div>
				</div>
				<div>
					<Image
						width={0}
						height={0}
						layout="responsive"
						src={"/404-error.svg"}
						alt="imagem de  error"
					/>
				</div>
			</main>
			<Footer />
		</>
	);
}
